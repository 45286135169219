import { useState } from "react";
import axios from "axios";
import { AllBookingContext } from "../../context/Context";
import Redirect from "tools/Redirect";

export function BookingState({ children }) {
  const [allBooking, setAllBooking] = useState([]);

  function getBooking() {
    axios
      .get("/booking")
      .then((res) => {
        if (res.status === 200) {
          setAllBooking(res.data);
        }
      })
      .catch((error) => {
        Redirect("/login");
      });
  }

  const value = { getBooking, allBooking };
  return (
    <AllBookingContext.Provider value={value}>
      {children}
    </AllBookingContext.Provider>
  );
}
