import { lazy } from "react";
import { useRoutes } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import LoginLayout from "../Layyouts/AuthLayout/LoginLayout";
// import IndexPage from "../Pages/Index/IndexPage";
import Category from "Pages/Category/Catrgory";
import FoodType from "Pages/FoodType/FoodType";
import Error404 from "../Components/404/Error404";
import { OneListings } from "../Components/listing/OneListings";
import ListingType from "Pages/ListingType/ListingType";
import Convenience from "Pages/Convenience/Convenience";
import UserVender from "Pages/UserVender/UserVender";
import App from "App";
import { Profile } from "Pages/Profile/Profile";
import { Translate } from "Pages/Translate/Translate";

const AllUsers = lazy(() => import("../Pages/Users/AllUsers"));
const Listing = lazy(() => import("../Pages/Listing/Listing"));
const AllBooking = lazy(() => import("../Pages/Booking/AllBooking"));
const ListingStatus = lazy(() =>
  import("../Components/listingStatus/ListingStatus")
);
const Priority = lazy(() => import("../Pages/priority/Priority"));
const UnitList = lazy(() => import("../Components/unit/UnitList"));

export function Router() {
  //   const auth = useAuthUser();
  return useRoutes([
    {
      element: <Profile />,
      index: true,
    },
    {
      path: "/users",
      element: <AllUsers />,
    },
    {
      path: "/booking",
      element: <AllBooking />,
    },
    {
      path: "/all-listing",
      element: <Listing />,
    },
    {
      path: "/all-listing/:id",
      element: <OneListings />,
    },
    {
      path: "priority",
      element: <Priority />,
    },
    {
      path: "listingStatus",
      element: <ListingStatus />,
    },
    {
      path: "listingType",
      element: <ListingType />,
    },
    {
      path: "unitList",
      element: <UnitList />,
    },
    {
      path: "foodType",
      element: <FoodType />,
    },
    {
      path: "category",
      element: <Category />,
    },
    {
      path: "convenience",
      element: <Convenience />,
    },
    {
      path: "userVender",
      element: <UserVender />,
    },
    {
      path: "translate",
      element: <Translate />,
    },
  ]);
}
