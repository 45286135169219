import React, { useEffect, useState } from "react";
import { Input, Form, Button, Row, Divider, Skeleton } from "antd";
import { useForm } from "antd/es/form/Form";

const { Item } = Form;
const { TextArea } = Input;

export function TranslateForm({ loading, data, index, editDataText }) {
  let [formname, setFormName] = useState("basic");

  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(data);
    setFormName(formname + index);
  }, []);
  return loading ? (
    <Skeleton />
  ) : (
    <>
      <Form
        name={formname}
        onFinish={editDataText}
        loading={loading}
        form={form}
      >
        <Item name="key" hidden={true}>
          <TextArea />
        </Item>
        <Item name="nameuz" label="Nomi UZ">
          <TextArea />
        </Item>
        <Item name="nameru" label="Nomi RU">
          <TextArea />
        </Item>
        <Item name="nameen" label="Nomi EN">
          <TextArea />
        </Item>
        <Row justify="end">
          <Item>
            <Button type="primary" danger htmlType="submit">
              Saqlash
            </Button>
          </Item>
        </Row>
      </Form>
      <Divider />
    </>
  );
}
