import React from "react";
import { TranslateForm } from "Components/translate/TranslateForm";
import { useTranslate } from "Store/Translate/useTranslate";
import { FloatButton } from "antd";

export function Translate() {
  const { loading, translateText, editDataText } = useTranslate();

  return (
    <>
      {translateText.map((text, index) => (
        <TranslateForm
          key={text.key}
          index={index}
          data={text}
          loading={loading}
          editDataText={editDataText}
        />
      ))}

      <FloatButton.BackTop visibilityHeight={600} />
    </>
  );
}
