import React, { useState } from "react";
import { Translate } from "context/Context";
import axios from "axios";
import Redirect from "tools/Redirect";
import Swal from "sweetalert2";

export default function TranslateState({ children }) {
  const [translateText, setTranslateText] = useState([]);
  const [loading, setLoading] = useState(false);
  function getDataText() {
    setLoading(true);
    axios
      .get("translate")
      .then((response) => {
        if (response.status === 200) {
          setTranslateText(response.data);
        }
        // console.log(response);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  function editDataText(value) {
    setLoading(true);
    axios
      .put("translate/" + value.key, value)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Tarjima yangilandi",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Tarjimani yangilashda hatolik",
            icon: "error",
          });
        }
      })
      .catch(() => Redirect("/login"))
      .finally(() => setLoading(false));
  }

  return (
    <Translate.Provider
      value={{ loading, translateText, getDataText, editDataText }}
    >
      {children}
    </Translate.Provider>
  );
}
