import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import LoginLayout from "Layyouts/AuthLayout/LoginLayout";
import Error404 from "Components/404/Error404";
import App from "App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <DevSupport
      ComponentPreviews={ComponentPreviews}
      useInitialHook={useInitial}
    >
      <AuthProvider authType="localstorage" authName="_auth">
        <Routes>
          <Route
            path="/*"
            element={
              <RequireAuth loginPath="/login">
                <App />{" "}
              </RequireAuth>
            }
          />
          <Route path="*" element={<Error404 />} />
          <Route path="/login" element={<LoginLayout />} />
        </Routes>
      </AuthProvider>
    </DevSupport>
  </BrowserRouter>
);

reportWebVitals();

/*
REACT_APP_URL=https://admin.eaten.uz/api/
REACT_APP_IMAGE_URL=https://admin.eaten.uz/
REACT_APP_IMAGE_UPLOAD_URL=https://admin.eaten.uz/api/upload-file
*/
