import { useAuthUser } from "react-auth-kit";
import { Card, Col, Row, Statistic } from "antd";
import { NavLink } from "react-router-dom";
import { ArrowUpOutlined } from "@ant-design/icons";

export function Profile() {
  const auth = useAuthUser();
  return (
    <>
      <h1>
        XUSH KELIBSIZ ({auth().login}) {auth().firstname} {auth().lastname} !!!
      </h1>
      <Row gutter={25} style={{ margin: "25px 0px" }}>
        <Col span={12}>
          <NavLink to="all-listing">
            <Card bordered={false}>
              <Statistic
                title="Jami E'lonlar"
                value={5}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix="ta E'lon"
              />
            </Card>
          </NavLink>
        </Col>
        <Col span={12}>
          <NavLink to="category">
            <Card bordered={false}>
              <Statistic
                title="Jami Kategoriyalar"
                value={5}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix="ta Kategoriya"
              />
            </Card>
          </NavLink>
        </Col>
      </Row>
      <Row gutter={25} style={{ margin: "25px 0px" }}>
        <Col span={12}>
          <NavLink to={"listingType"}>
            <Card bordered={false}>
              <Statistic
                title="Jami E'lon turlarilar"
                value={5}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix="ta Tur"
              />
            </Card>
          </NavLink>
        </Col>
        <Col span={12}>
          <NavLink to="foodType">
            <Card bordered={false}>
              <Statistic
                title="Jami Taom turlari"
                value={5}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix="ta Taom"
              />
            </Card>
          </NavLink>
        </Col>
      </Row>{" "}
      <Row gutter={25} style={{ margin: "25px 0px" }}>
        <Col span={12}>
          <NavLink to="convenience">
            <Card bordered={false}>
              <Statistic
                title="Jami Qulayliklar"
                value={5}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix="ta Qulaylik"
              />
            </Card>
          </NavLink>
        </Col>
      </Row>
    </>
  );
}
