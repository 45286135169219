import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { AllListingState } from "./Store/Listing/AllListingState";
import { SitiesAndDistrictState } from "./Store/CitiesAndDistricts/CitiesAndDistrictState";
import { BookingState } from "./Store/Booking/BookingState";
import { UnitState } from "./Store/unit/UnitState";
import { PriorityState } from "./Store/Priority/PriorityState";
import { ListingStatusState } from "./Store/ListingStatus/ListingStatusState";
import { FoodTypeState } from "Store/FoodType/FoodTypeState";
import { CategoryState } from "Store/Category/CategoryState";
import { ListingTypeState } from "Store/ListingType/ListingTypeState";
import { ConvenienceState } from "Store/Convenience/ConvenienceState";
import { UserVenderState } from "Store/UserVenders/UserVenderState";
import AdminLayout from "Layyouts/AdminLayout";
import { useEffect, useState } from "react";
import { AgentLayout } from "Layyouts/AgentLayout";
import { configs } from "config/config";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import TranslateState from "Store/Translate/TranslateState";

export default function App() {
  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  axios.defaults.baseURL = configs.apiUrl;
  axios.defaults.headers.common["Authorization"] = authHeader();
  axios.defaults.headers.post["Content-Type"] = "application/json";

  const [layout, setLayout] = useState(null);

  useEffect(() => {
    if (auth().role === "0") {
      setLayout(<AdminLayout />);
    } else {
      setLayout(<AgentLayout />);
    }
  }, []);

  return (
    <UserVenderState>
      <ConvenienceState>
        <ListingTypeState>
          <CategoryState>
            <FoodTypeState>
              <ListingStatusState>
                <PriorityState>
                  <UnitState>
                    <SitiesAndDistrictState>
                      <AllListingState>
                        <TranslateState>
                          <BookingState>{layout}</BookingState>
                        </TranslateState>
                      </AllListingState>
                    </SitiesAndDistrictState>
                  </UnitState>
                </PriorityState>
              </ListingStatusState>
            </FoodTypeState>
          </CategoryState>
        </ListingTypeState>
      </ConvenienceState>
    </UserVenderState>
  );
}
